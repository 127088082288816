import React, { FunctionComponent, useEffect, useState } from 'react';
import {  Box, Button, ButtonBase, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useHistory, useLocation } from 'react-router-dom';
import UserService from '../services/UserService';
import { setUserVehicles } from '../slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { User, Vehicle, Event, ConfirmationDialog } from '@movalib/movalib-commons';
import { setSnackbar } from '../slices/snackbarSlice';
import AddVehicleDialog from '../dialog/AddVehicleDialog';
import SearchGarageDialog from '../dialog/SearchGarageDialog';
import GreenLeafImage from "../assets/images/leaf_green_large.png";
import CarFigure from "../assets/images/car_figure.png";
import { createCookie, deleteCookie, flexCenter, getRotatedIconStyle, readCookie } from '../helpers/Tools';
import HomeIcon from '@mui/icons-material/HomeRounded';
import CarIcon from '@mui/icons-material/DriveEtaRounded';
import AppointmentsIcon from '@mui/icons-material/TodayRounded';
import AppointmentScheduledIcon from '@mui/icons-material/EventNoteRounded';
import HistoryIcon from '@mui/icons-material/HistoryRounded';
import SearchIcon from '@mui/icons-material/SearchRounded';
import AlertIcon from '@mui/icons-material/NotificationsActiveRounded';
import ScheduledIcon from '@mui/icons-material/EventAvailableRounded';
import { TabPanel } from '@mui/lab';
import VehicleSimpleCard from '../components/VehicleSimpleCard';
import Loader from '../components/Loader';
import AppointmentSimpleCard from '../components/AppointmentSimpleCard';
import DigitalPassportIcon from "../assets/images/speedometer.png";
import AppointmentDetailsDialog from '../dialog/appointment/AppointmentDetailsDialog';
import VehicleDetailsDialog from '../dialog/VehicleDetailsDialog';
import EmptyCard from '../components/EmptyCard';
import Logger from '../helpers/Logger';
import { COOKIE_REDIRECT_REQUEST, COOKIE_STOP_PUSH_ADD_VEHICLE } from '../helpers/Constants';

export enum TabItems {
    HOME,
    APPOINTMENTS,
    VEHICLES,
    // Ces deux valeurs sont propres auux sous-onglets de l'onglet "Rendez-vous"
    HISTORY,
    SCHEDULED
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface HomeProps {
    activeTab: TabItems
}

const Home: FunctionComponent<HomeProps> = ({ activeTab }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    // Récupération des données de l'utilisateur connecté
    const [currentUser, setCurrentUser] = useState<User | undefined>(
        useSelector((state:RootState) => state.user.user )
    );
    const [openAddVehicle, setOpenAddVehicle] = useState(false);
    const [openSearchGarage, setOpenSearchGarage] = useState(false);

    const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>(undefined);
    const [openVehicleDetails, setOpenVehicleDetails] = useState(false);

    const [selectedAppointment, setSelectedAppointment] = useState<Event | undefined>(undefined);
    const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false);

    const [vehicles, setVehicles] = useState<Vehicle[] | undefined>();
    const [activeAppointments, setActiveAppointment] = useState<Event[]>([]);
    const [pastAppointments, setPastAppointments] = useState<Event[]>([]);

    // Onglet par défaut (Accueuil)
    const [selectedTab, setSelectedTab] = useState<number>(activeTab ? activeTab : TabItems.HOME);
    const [selectedAppointmentTab, setSelectedAppointmentTab] = useState<number>(TabItems.SCHEDULED);
    const [currentUpload, setCurrentUpload] = useState<boolean>(false);


    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ 
                overflowY: 'hidden', 
                width: '100%',
                display: 'flex',
                justifyContent: 'center' }} 
            {...other}
          >
            {value === index && (
              <Box sx={{ px: 3, width: isMobile ? '100%' : '50%'}}>
                <Box component="div">{children}</Box>
              </Box>
            )}
          </div>
        );
    }

    useEffect(() => {
        if (activeTab) {
          setSelectedTab(activeTab);
        }
      }, [activeTab]);

    /**
     * Au chargement de la page d'accueil, on charge les véhicules de l'utilisateur
     * En cas d'absence, on déclenche le formulaire d'ajout
     */
    useEffect(() => {

        if(selectedTab == TabItems.HOME || selectedTab == TabItems.APPOINTMENTS || selectedTab == TabItems.SCHEDULED){
            // L'onglet des rendez-vous par défaut étant SCHEDULED, on les charges au lancement
            refreshActiveAppointments();
        }

        if(selectedTab == TabItems.HISTORY){
            // L'onglet des rendez-vous par défaut étant SCHEDULED, on les charges au lancement
            refreshPastAppointments();
        }

        if(selectedTab == TabItems.VEHICLES){
            refreshUserVehicles();
        }

        // Si nous détectons la présence d'une demande de rediction, nous l'exécutons puis supprimons le cookie
        if(readCookie(COOKIE_REDIRECT_REQUEST)){
            history.push(readCookie(COOKIE_REDIRECT_REQUEST) ?? '');
            deleteCookie(COOKIE_REDIRECT_REQUEST);
        }


    }, [selectedTab]);

    const refreshActiveAppointments = () => {

        setLoading(true);
        // Récupération des rendez-vous actifs de l'utilisateur courant
        UserService.getActiveUserAppointments(dispatch)
            .then(appointments => {

                Logger.info(appointments);

                if(appointments && appointments.length > 0){
                    setActiveAppointment(appointments);
                } else {
                    setActiveAppointment([]);
                }

            }).catch(error => {
                Logger.info(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const refreshPastAppointments = () => {

        setLoading(true);
        // Récupération des rendez-vous passés de l'utilisateur courant
        UserService.getPastUserAppointments(dispatch)
            .then(appointments => {

                Logger.info(appointments);

                if(appointments && appointments.length > 0){
                    setPastAppointments(appointments);
                } else {
                    setPastAppointments([]);
                }

            }).catch(error => {
                Logger.info(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const refreshUserVehicles = () => {

        setLoading(true);
        // Récupération des véhicules de l'utilisateur courant
        UserService.getUserVehicles(dispatch)
            .then(vehicles => {

                if(vehicles && vehicles.length > 0){
                    // Persistance des véhicules dans le state global
                    dispatch(setUserVehicles(vehicles));
                    setVehicles(vehicles);
                } else {
                    // Ouverture formulaire d'ajout d'un premier véhicule
                    setVehicles([]);
                    // On déclenche l'ouverture automatique de l'ajout d'un véhicule uniquement si le cookie de détection du stop push n'est pas détecté
                    if(isFirstVehicle()){
                        setOpenAddVehicle(true);
                    }
                }

            }).catch(error => {
                Logger.info(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleOpenSearchGarage = () => {
        setOpenSearchGarage(true);
    }

    const handleOpenAddVehicle = () => {
        // Ouverture formulaire d'ajout d'un premier véhicule
        setOpenAddVehicle(true);
    }

    const handleConfirmAddFirstVehicle = (returnVehiclePlate: string, returnVehicleDescription: string) => {
        // On rafraîchit la liste des véhicules de l'utilisateur connecté
        refreshUserVehicles();

        // On ferme la boite de dialogue
        setOpenAddVehicle(false);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleAppointmentsTabChange = (event: React.SyntheticEvent, newValue: number) => {

        setSelectedAppointmentTab(newValue);

        switch(newValue){
            case TabItems.SCHEDULED:
                refreshActiveAppointments();
                break;
            case TabItems.HISTORY:
                refreshPastAppointments();
                break;
        }
    };

    const handleSelectVehicle = (vehicle: Vehicle) => {
        if(vehicle){
            setSelectedVehicle(vehicle);
            setOpenVehicleDetails(true);
        }
    }

    const handleOnCloseVehicleDetail = (refresh:boolean) => {
        setOpenVehicleDetails(false)
        if(refresh){
            refreshUserVehicles();
        }
    }

    const handleSelectAppointment = (appointment: Event) => {
        if(appointment){
            setSelectedAppointment(appointment);
            setOpenAppointmentDetails(true);
        }
    }

    const handleOnCloseAppointmentDetail = (refresh:boolean) => {
        setOpenAppointmentDetails(false)
        if(refresh){
            refreshActiveAppointments();
        }
    }

    const handleOnCloseAddVehicle = () => {
        setOpenAddVehicle(false);

        // On inscrit un petit cookie de détection de cette fermeture pour éviter de push sans arrêt
        createCookie(COOKIE_STOP_PUSH_ADD_VEHICLE, "true");
    }

    const isFirstVehicle = () => {
        // Si le cookie de détection d'arrêt de push, alors on ouvre la boite de dialogue dans son mode normal
        return (!vehicles || vehicles?.length == 0) && !Boolean(readCookie(COOKIE_STOP_PUSH_ADD_VEHICLE))
    }

    return (
        <>

        {/* Le padding top de 6 correspond à 48px (hauteur de la barre de menu) */}
        {/* Le margin bottom top de 10 correspond à 80px (hauteur du menu bas + 16 px de marge) */}
        <Grid container sx={{ pt: 7, mb: 11 }} >

            <img src={GreenLeafImage} style={{
                position: 'fixed', 
                float:'left',
                width: '150px',
                height: '350px',
                top: '-20%',
                zIndex: -10,
                left: 0,
                opacity: '0.3'}} alt='Feuille Verte Movalib'></img>

            {/* Onglet : ACCUEIL */}
            <TabPanel value={selectedTab} index={TabItems.HOME} >

                {/* TITRE */}
                <Grid item xs={12} sx={{ textAlign:'center', mt: 2, zIndex: 100 }}>
                    <Typography variant="h6" sx={{ mb: 3, ml: 1,  mr: 1, fontWeight:400 }}>Prenez rendez-vous chez un <b>garagiste</b> </Typography>
                    <Button onClick={handleOpenSearchGarage} color="primary" sx={{ width: '80%' }} variant='contained'>
                        <SearchIcon sx={{ mr: 1}} />Rechercher
                    </Button>
                </Grid>

                    {/* Rappels prochains rendez-vous */}
                    
                        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems:'center', mt: 5 }} >
                            {loading  ? <Loader loading={loading} padding={0} /> :
                                <Card variant='outlined' sx={{ width: isMobile ? '95%' : 450, overflow: 'visible', mt: 2 }}>
                                    <AlertIcon style={getRotatedIconStyle()} sx={{ color: theme.palette.grey[300], top: '-20px !important' }} />
                                    <CardContent sx={{ pt: 0, pb: 0}}>
                                        <Typography variant="subtitle1" component="div" align="center" sx={{ mt: '-32px' }}>
                                        {activeAppointments && activeAppointments.length > 0 ? 
                                        <>
                                        Vous avez <b>{activeAppointments.length} rendez-vous</b> actif{activeAppointments.length > 1 ? 's' : ''}.
                                        </>
                                        : 
                                        <>Aucun rendez-vous à venir.</> 
                                        }
                                        </Typography>
                                    </CardContent>
                                </Card>  
                            }    
                        </Grid>
                    

                    {/* Alerte infos passport digital */}
                {/*     MVP : Désactivé
                
                    <Grid item xs={12} sx={{ mt: 5, display: 'flex', flexDirection: 'column', alignItems:'center', }} >
                        <Card variant='outlined' sx={{ width: isMobile ? '95%' : 450, overflow: 'visible', mt: 2 }}
                        >
                            <img src={DigitalPassportIcon} style={{
                                    position: 'relative',
                                    top: '-28px',
                                    zIndex: 200,
                                    height: '45px',
                                    marginBottom: '-30px'
                            }}/>
                            <CardContent sx={{ pt: 0, pb: 0}}>
                                <Typography variant="subtitle1" component="div" align="center" sx={{ mb:1 }}>
                                    C'est quoi le <b>Passport Digital</b> Movalib ?
                                </Typography>
                            </CardContent>
                        </Card>      
                    </Grid>

                */}
            </TabPanel>

            {/* Onglet : RENDEZ-VOUS */}
            <TabPanel value={selectedTab} index={TabItems.APPOINTMENTS} >
                    
                    <Grid item xs={12} sx={{ textAlign:'center', pt: 2, zIndex: 100, height: '80vh', display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="h6" sx={{ mb: 1, ml: 1,  mr: 1, fontWeight:400 }}>Consultez l'historique de vos <b>rendez-vous</b></Typography>
                        <Tabs
                            value={selectedAppointmentTab}
                            onChange={handleAppointmentsTabChange}
                            aria-label="appointments-tabs"
                            variant="fullWidth"
                            sx={{ minHeight: 72, mb: 1 }}
                        >
                            <Tab icon={<AppointmentScheduledIcon />} iconPosition="start" label="A venir" value={TabItems.SCHEDULED} />
                            <Tab icon={<HistoryIcon />} iconPosition="start" label="Historique" value={TabItems.HISTORY} />
                        </Tabs>

                        {/** RENDEZ-VOUS ACTIFS */}
                        {selectedAppointmentTab === TabItems.SCHEDULED &&
                            <div style={{ flexGrow: 1, overflowY: 'auto'}}>
                                {activeAppointments && activeAppointments?.map((appointment, index) => (
                                    <Grid item xs={12} key={(index+1) * 50} sx={{ mt: 3, mx: isMobile ? 0 : 2 }} >
                                        <AppointmentSimpleCard appointment={appointment} onCardClick={handleSelectAppointment} />
                                    </Grid>
                                ))}
                            </div>
                        }                      
                        {/** RENDEZ-VOUS PASSES */}
                        {selectedAppointmentTab === TabItems.HISTORY && 
                            <div style={{ flexGrow: 1, overflowY: 'auto'}}>
                                {pastAppointments && pastAppointments?.map((appointment, index) => (
                                    <Grid item xs={12} key={(index+2) * 100} sx={{ mt: 3 }} >
                                        <AppointmentSimpleCard appointment={appointment} onCardClick={handleSelectAppointment} />
                                    </Grid>
                                ))}
                            </div>
                        }

                        {/** AUCUN rendez-vous */}
                        {((pastAppointments && selectedAppointmentTab === TabItems.HISTORY && pastAppointments.length === 0) ||
                         (activeAppointments && selectedAppointmentTab === TabItems.SCHEDULED && activeAppointments.length === 0)) &&
                            <Grid item xs={12} sx={{ mt: 2 }} >
                                <EmptyCard />
                            </Grid>
                        }

                        <Loader loading={loading} />
                    </Grid>
            </TabPanel>

            {/* Onglet : VEHICULES */}
            <TabPanel value={selectedTab} index={TabItems.VEHICLES}  >

                {/* TITRE */}
                <Grid item xs={12} sx={{ textAlign:'center', mt: 2, zIndex: 100 , width: '100%' }}>
                    <Typography variant="h6" sx={{ mb: 3, ml: 1,  mr: 1, fontWeight:400 }}>Gèrez l'ensemble de vos <b>véhicules</b></Typography>
                    <Button onClick={handleOpenAddVehicle} color="primary" sx={{ width: '80%' }} variant='contained'>
                        Ajouter un véhicule
                    </Button>    

                    {vehicles && vehicles?.map((vehicle, index) => (
                        <Grid item xs={12} key={index} sx={{ mt: 5 }} style={flexCenter} >
                            <VehicleSimpleCard vehicle={vehicle} onClick={handleSelectVehicle} />
                        </Grid>
                    ))}

                    {/** AUCUN véhicule */}
                    {vehicles && vehicles.length === 0 &&
                        <Grid item xs={12} sx={{ mt: 5 }} > 
                            <EmptyCard />
                        </Grid>
                    }

                </Grid>
            
            </TabPanel>


            {/* DIALOG BOX */}
            {openAddVehicle && <AddVehicleDialog open={openAddVehicle} 
                onConfirm={handleConfirmAddFirstVehicle} isFirstVehicle={isFirstVehicle() ?? true} 
                onClose={handleOnCloseAddVehicle}/>}

            {openSearchGarage && <SearchGarageDialog open={openSearchGarage} onClose={() => setOpenSearchGarage(false)} />}

            {openAppointmentDetails && selectedAppointment && <AppointmentDetailsDialog open={openAppointmentDetails} 
                appointment={selectedAppointment} onClose={handleOnCloseAppointmentDetail} />}

            {openVehicleDetails && selectedVehicle && <VehicleDetailsDialog open={openVehicleDetails} currentUpload={currentUpload} setCurrentUpload={setCurrentUpload}
                vehicleId={selectedVehicle.id} onClose={handleOnCloseVehicleDetail} />}

        </Grid>

        {isMobile && <Tabs value={selectedTab} onChange={handleTabChange} aria-label="home-tabs" 
            TabIndicatorProps={{
                style: {
                    bottom: 'auto',
                    top: 0,
                    backgroundColor: '#F29ABA'
                }
            }}            
            variant="fullWidth"
            textColor="primary"
            sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: theme.palette.grey[100], zIndex:400, mt: 9  }}>
            <Tab icon={<HomeIcon />} label="Accueil" sx={{ fontSize: 12 }} value={TabItems.HOME}  />
            <Tab icon={<AppointmentsIcon />} label="Rendez-vous" sx={{ fontSize: 12 }} value={TabItems.APPOINTMENTS}  />
            <Tab icon={<CarIcon />} label="Véhicules" sx={{ fontSize: 12 }} value={TabItems.VEHICLES} />
        </Tabs> }

        </>
    );
};

export default Home;