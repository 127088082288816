import {
	GenderSelector,
	MovaDialog,
	MovaFormField,
	User,
	capitalizeFirstLetter,
	validateEmail,
	validateField,
	validatePhoneNumber,
} from "@movalib/movalib-commons";
import {
	Alert,
	Box,
	Grid,
	IconButton,
	InputAdornment,
	SelectChangeEvent,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useState, type FC, useEffect } from "react";
import UserService from "../services/UserService";
import { useDispatch } from "react-redux";
import Logger from "../helpers/Logger";
import { setSnackbar } from "../slices/snackbarSlice";
import ConfirmIcon from "@mui/icons-material/Check";
import Loader from "../components/Loader";
import { setUserData } from "../slices/userSlice";
import { deleteCookies } from "../helpers/Tools";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

interface ProfileDialogProps {
	open: boolean;
	onClose: () => void;
}

type UserProfileForm = {
	email: MovaFormField;
	firstname: MovaFormField;
	lastname: MovaFormField;
	phoneNumber: MovaFormField;
	securityCode: MovaFormField;
};

const initialFormState = {
	email: { value: "", error: "", isValid: true },
	firstname: { value: "", error: "", isValid: true },
	lastname: { value: "", error: "", isValid: true },
	phoneNumber: { value: "", error: "", isValid: true },
	securityCode: { value: "", error: "", isValid: true },
};

const ProfileDialog: FC<ProfileDialogProps> = ({ open, onClose }) => {
	const theme = useTheme();
	const history = useHistory();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const dispatch = useDispatch();
	const [form, setForm] = useState<UserProfileForm>(initialFormState);
	const [user, setUser] = useState<User | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	const [localOpen, setLocalOpen] = useState<boolean>(open);
	const [message, setMessage] = useState<string>("");
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [phoneNumberModified, setPhoneNumberModified] =
		useState<boolean>(false);
	const [emailModified, setEmailModified] = useState<boolean>(false);
	const [securityCodeCheck, setSecurityCodeCheck] = useState<boolean>(false);
	useEffect(() => {
		purgeLocalState();
		refreshUser();
		setLocalOpen(open);
	}, [open]);

	useEffect(() => {
		if (user) {
			setForm((prevForm) => ({
				...prevForm,
				["email"]: { ...prevForm["email"], value: user.email },
			}));
			setForm((prevForm) => ({
				...prevForm,
				["firstname"]: { ...prevForm["firstname"], value: user.firstname },
			}));
			setForm((prevForm) => ({
				...prevForm,
				["lastname"]: { ...prevForm["lastname"], value: user.lastname },
			}));
			setForm((prevForm) => ({
				...prevForm,
				["phoneNumber"]: {
					...prevForm["phoneNumber"],
					value: user.phoneNumber ?? "",
				},
			}));
		}
	}, [user]);

	const refreshUser = () => {
		setLoading(true);

		// Récupération des véhicules de l'utilisateur courant
		UserService.getCurrentUser(dispatch)
			.then((user) => {
				if (user) {
					// Actualisation en local et dans le localStorage
					setUser(user);
					dispatch(setUserData(user));
				}
			})
			.catch((error) => {
				Logger.error(error);
				dispatch(
					setSnackbar({ open: true, message: error, severity: "error" }),
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const purgeLocalState = () => {
		setMessage("");
		setErrorMessage("");
		setUser(undefined);
	};
	const handleOnClose = () => {
		purgeLocalState();
		onClose();
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	): void => {
		handleChange(e.target.name, e.target.value);
	};

	const handleChange = (name: string, value: string) => {
		const fieldName: string = name;
		let fieldValue: string = value;

		// Le contrôle du format du n° de téléphone se fait en live lors de la saisie
		if (fieldName == "phoneNumber") {
			if (!validatePhoneNumber(fieldValue)) return;
		}

		// Capitalisation automatique du prénom
		if (fieldName == "firstname") {
			fieldValue = capitalizeFirstLetter(fieldValue);
		}

		// Uppercase automatique pour le nom
		if (fieldName == "lastname") {
			fieldValue = fieldValue.toUpperCase();
		}
		const newField: MovaFormField = {
			[fieldName]: { value: fieldValue, isValid: true },
		};

		setForm({ ...form, ...newField });
	};

	const validateForm = () => {
		let newForm: UserProfileForm = { ...form };

		// Validator pour les champs obligatoires
		newForm.firstname = validateField(
			form.firstname,
			(value) => !!value,
			"Champ obligatoire",
		);
		newForm.lastname = validateField(
			form.lastname,
			(value) => !!value,
			"Champ obligatoire",
		);
		newForm.email = validateField(
			form.email,
			(value) => !!value,
			"Champ obligatoire",
		);
		// Validator pour l'email
		newForm.email = validateField(
			form.email,
			validateEmail,
			"Adresse email invalide",
		);

		// Validator 'phoneNumber'
		if (newForm.phoneNumber?.value) {
			newForm.phoneNumber = validateField(
				form.phoneNumber,
				(value) => value.length == 10,
				"Le n° de téléphone est invalide",
			);
		}

		setForm(newForm);
		if (emailModified) {
			return newForm.email.isValid;
		}

		if (phoneNumberModified) {
			return newForm.phoneNumber.isValid;
		}

		return (
			newForm.firstname.isValid &&
			newForm.lastname.isValid &&
			newForm.email.isValid &&
			newForm.phoneNumber.isValid
		);
	};

	const handleSubmit = () => {
		if (securityCodeCheck) {
			setLoading(true);
			const req: any = {
				code: form.securityCode.value,
			};
			if (emailModified) {
				req.email = form.email.value;
			}
			if (phoneNumberModified) {
				req.phoneNumber = form.phoneNumber.value;
			}
			UserService.updatePhoneNumberOrEmail(req)
				.then(() => {
					setMessage(
						"Mise à jour effectuée avec succès. Vous allez être déconnecté...",
					);
					deleteCookies();
					// Suppression des données persistées en localStorage
					setTimeout(() => {
						localStorage.clear();
						history.push("/login");
					}, 4000);
				})
				.catch((error) => {
					Logger.error(error);
					setErrorMessage(error);
				})
				.finally(() => {
					setLoading(false);
				});
		} else if (validateForm()) {
			setLoading(true);
			setMessage("");

			// Création de la requête
			let req: any = {
				firstname: form.firstname.value,
				lastname: form.lastname.value,
			};
			if (emailModified) {
				req = { email: form.email.value };
			} else if (phoneNumberModified) {
				req = { phoneNumber: form.phoneNumber.value };
			}

			UserService.updateUserProfile(req)
				.then((response) => {
					if (phoneNumberModified || emailModified) {
						setSecurityCodeCheck(true);
					}
					if (!phoneNumberModified && !emailModified) {
						setMessage("Mise à jour effectuée avec succès");
						setUser(response);
						dispatch(setUserData(response));
					}
				})
				.catch((error) => {
					Logger.error(error);
					setErrorMessage(error);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const isSubmitAuthorized = () => {
		let changeDetected: boolean = true;
		if (form && user) {
			if (securityCodeCheck) {
				return form.securityCode.value.length !== 6;
			}
			if (emailModified) {
				return user?.email === form.email.value;
			}
			if (phoneNumberModified) {
				return user?.phoneNumber === form.phoneNumber.value;
			}

			// On active le bouton de soumission si au moins une des valeurs a été modifiée
			changeDetected =
				user?.firstname !== form.firstname.value ||
				user?.lastname !== form.lastname.value;
		}

		return !changeDetected;
	};

	return (
		<>
			{user && (
				<MovaDialog
					fullScreen={isMobile}
					open={localOpen}
					onClose={handleOnClose}
					leafImageColor="pink"
					title="PROFIL"
					actions={
						<LoadingButton
							loading={loading}
							onClick={handleSubmit}
							color="primary"
							sx={{ width: "90%" }}
							variant="contained"
							disabled={isSubmitAuthorized()}
						>
							<ConfirmIcon sx={{ mr: 1 }} />
							Enregistrer
						</LoadingButton>
					}
				>
					{!phoneNumberModified && !emailModified && !securityCodeCheck && (
						<Box component="form" noValidate sx={{ mt: 1 }}>
							<TextField
								margin="normal"
								required
								autoFocus
								fullWidth
								id="firstname"
								label="Prénom"
								name="firstname"
								autoComplete="given-name"
								onChange={(e) => handleInputChange(e)}
								value={form.firstname.value}
								error={Boolean(form.firstname.error)}
								helperText={form.firstname.error}
							/>
							<TextField
								margin="normal"
								required
								fullWidth
								id="lastname"
								label="Nom"
								name="lastname"
								autoComplete="family-name"
								onChange={(e) => handleInputChange(e)}
								value={form.lastname.value}
								error={Boolean(form.lastname.error)}
								helperText={form.lastname.error}
							/>

							<TextField
								margin="normal"
								required
								fullWidth
								id="email"
								label="Adresse email"
								name="email"
								autoComplete="email"
								disabled={true}
								onChange={(e) => handleInputChange(e)}
								value={form.email.value}
								error={!form.email.isValid}
								helperText={form.email.error}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												onClick={() => setEmailModified(true)}
											>
												<EditIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>

							<TextField
								margin="normal"
								fullWidth
								id="phoneNumber"
								label="N° de téléphone"
								name="phoneNumber"
								autoComplete="tel"
								disabled={true}
								onChange={(e) => handleInputChange(e)}
								value={form.phoneNumber?.value}
								error={Boolean(form.phoneNumber?.error)}
								helperText={form.phoneNumber?.error}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												edge="end"
												onClick={() => setPhoneNumberModified(true)}
											>
												<EditIcon />
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Box>
					)}

					{emailModified && !securityCodeCheck && (
						<Grid container sx={{ mt: 2 }}>
							<Grid item xs={12}>
								<Typography variant="body1">
									Saisissez votre nouvelle adresse mail
								</Typography>
							</Grid>
							<Grid item xs={12} sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Adresse email"
									name="email"
									autoComplete="email"
									onChange={(e) => handleInputChange(e)}
									value={form.email.value}
									error={!form.email.isValid}
									helperText={form.email.error}
								/>
							</Grid>
							<Grid item xs={12} sx={{ mt: 2 }}>
								<Alert
									severity="info"
									variant="outlined"
									className="styled-alert"
								>
									L'email sera mis à jour après vérification du code reçu
								</Alert>
							</Grid>
						</Grid>
					)}

					{phoneNumberModified && !securityCodeCheck && (
						<Grid container sx={{ mt: 2 }}>
							<Grid item xs={12}>
								<Typography variant="body1" sx={{ mb: 2 }}>
									Saisissez votre nouveau numéro de téléphone
								</Typography>
							</Grid>
							<Grid item xs={12} sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									fullWidth
									id="phoneNumber"
									label="N° de téléphone"
									name="phoneNumber"
									autoComplete="tel"
									onChange={(e) => handleInputChange(e)}
									value={form.phoneNumber?.value}
									error={Boolean(form.phoneNumber?.error)}
									helperText={form.phoneNumber?.error}
								/>
							</Grid>
							<Grid item xs={12} sx={{ mt: 2 }}>
								<Alert
									severity="info"
									variant="outlined"
									className="styled-alert"
								>
									Le numéro de téléphone sera mis à jour après vérification du
									code reçu
								</Alert>
							</Grid>
						</Grid>
					)}

					{securityCodeCheck && (
						<Grid container sx={{ mt: 2 }}>
							<Grid item xs={12}>
								<Typography variant="body2" sx={{ mb: 2 }}>
									Saisissez le code de vérification reçu par{" "}
									{emailModified ? "email" : "sms"}
								</Typography>
							</Grid>
							<Grid item xs={12} sx={{ mt: 1 }}>
								<TextField
									label={`Code reçu par ${emailModified ? "email" : "sms"}`}
									required
									fullWidth
									name="securityCode"
									variant="outlined"
									autoFocus
									value={form.securityCode?.value}
									error={!form.securityCode?.isValid}
									helperText={
										Boolean(form.securityCode?.error)
											? form.securityCode?.error
											: null
									}
									onChange={(e) => handleInputChange(e)}
									inputProps={{
										maxLength: 6,
										inputMode: "numeric", // Affiche un clavier numérique sur les appareils mobiles
										pattern: "[0-9]*", // Permet seulement la saisie de chiffres
									}}
								/>
							</Grid>
							<Grid item xs={12} sx={{ mt: 1 }}>
								<Alert severity="warning" sx={{ mb: 2 }}>
									Attention, votre{" "}
									{emailModified ? "email" : "numéro de téléphone"} va être
									modifié. Vous allez être déconnecté à la validation.
								</Alert>
							</Grid>
						</Grid>
					)}

					{message && (
						<Alert severity="success" sx={{ mb: 2 }}>
							{message}
						</Alert>
					)}
					{errorMessage && (
						<Alert severity="error" sx={{ mb: 2 }}>
							{errorMessage}
						</Alert>
					)}
				</MovaDialog>
			)}
		</>
	);
};

export default ProfileDialog;
