import {  MovaAppType, MovaDialog, Vehicle, VehicleFullCard, VehicleService as CoreVehicleService, VehicleTire, MovaVehicleForm } from '@movalib/movalib-commons';
import { useState, type FC, useEffect, CSSProperties, FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import VehicleService from '../services/VehicleService';
import { setSnackbar } from '../slices/snackbarSlice';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Logger from '../helpers/Logger';
import UserService from '../services/UserService';

interface VehicleDetailsDialogProps {
    open: boolean,
    vehicleId: number,
    onClose: (refresh: boolean) => void;
    currentUpload: boolean;
    setCurrentUpload: (upload: boolean) => void;
}

const VehicleDetailsDialog: FunctionComponent<VehicleDetailsDialogProps> = ({ open, vehicleId, onClose, currentUpload, setCurrentUpload }) => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [localOpen, setLocalOpen] = useState<boolean>(open);
    const [loading, setLoading] = useState<boolean>(false);
    const [vehicle, setVehicle] = useState<Vehicle>();
    const [vehicleEditMode, setVehicleEditMode] = useState<boolean>(false);

    const dialogTitleStyle: CSSProperties = {
        color: theme.palette.text.primary,
        fontWeight: 400
    }
    useEffect(() => {
        setLocalOpen(open);
        refreshVehicle();
    }, [open]);


    const refreshVehicle = () => {

        setLoading(true);
        // Récupération des véhicules de l'utilisateur courant
        VehicleService.getVehicleDetails(dispatch, vehicleId)
            .then(vehicle => {
                if (vehicle) {
                    setVehicle(vehicle);
                    setVehicleEditMode(false);
                }

            }).catch(error => {
                Logger.error(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        return () => {
            purgeLocalState();
            setLocalOpen(false);
            setVehicleEditMode(false);
        }
    }, []);

    const purgeLocalState = () => {
        setVehicle(undefined);
    }

    const handleOnClose = () => {
        purgeLocalState();
        setLocalOpen(false);
        onClose(false);
    }

    const handleOnVehicleUpdate = (form: MovaVehicleForm) => {
        if (vehicle && form) {
            setLoading(true);

            // On crée la requête pour l'API
            const query = {
                currentMileage: form.currentMileage.value,
                averageMileagePerYear: form.averageMileagePerYear.value,
                tireWidth:
                    form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).width : undefined,
                tireHeight:
                    form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).height : undefined,
                tireDiameter:
                    form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).diameter : undefined,
                tireSpeedIndex:
                    form.tireSize.isValid && form.tireSize.value ? (form.tireSize.value as VehicleTire).speedIndex : undefined,
            };

            VehicleService.updateVehicle(dispatch, vehicle.id, query)
                .then((response) => {
                    Logger.info(response);

                    // Affichage notification utilisateur
                    dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
                    handleOnClose();

                    // On ne ferme pas la dialog box de l'évènement mais provoque juste le refresh des events
                    if (refreshVehicle) refreshVehicle();
                })
                .catch((error) => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleOnVehicleDelete = () => {

        if (vehicle) {
            setLoading(true);

            // Récupération des documents du véhicule
            UserService.deleteUserVehicle(dispatch, vehicle.id)
                .then(response => {
                    Logger.info(response);
                    // Affichage notification utilisateur
                    dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
                }).catch(error => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    // On ferme la boite de dialogue (avec demande de refresh de la l'écran précédent)
    purgeLocalState();
    setLocalOpen(false);
    onClose(true);
}

const handleOnVehicleError = (message: string) => {
    if (message) {
        Logger.error(message);
        dispatch(setSnackbar({ open: true, message: message, severity: 'error' }));
    }
};

const handleOnVehicleUploadDocument = (data: FormData) => {
    if (vehicle && data) {
        setLoading(true);
        setCurrentUpload(true);
        // Téléchargement du document
        CoreVehicleService.uploadVehicleDocument(MovaAppType.INDIVIDUAL, vehicle.id, data)
            .then((response) => {
                // Affichage notification utilisateur
                setSnackbar({ open: true, message: response.data as string, severity: 'success' });

                refreshVehicle();
            })
            .catch((error: string) => {
                Logger.info(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
                setCurrentUpload(false);
            });
    }
};

const handleOnVehicleDeleteDocument = (documentId: string) => {
    if (vehicle && documentId) {
        setLoading(true);

        // Récupération des documents du véhicule
        CoreVehicleService.deleteVehicleDocument(MovaAppType.INDIVIDUAL, vehicle.id, documentId)
            .then((response) => {
                Logger.info(response);

                // Affichage notification utilisateur
                dispatch(setSnackbar({ open: true, message: response.data as string, severity: 'success' }));

                refreshVehicle();
            })
            .catch((error) => {
                Logger.info(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
            })
            .finally(() => {
                setLoading(false);
            });
    }
};

return (
    <>
        {vehicle &&
            <MovaDialog fullScreen={isMobile} open={localOpen} onClose={handleOnClose} leafImageColor="green"
                title="VOTRE VÉHICULE" titleStyle={dialogTitleStyle}
            >
                <Grid container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

                    <VehicleFullCard
                        currentUpload={currentUpload}
                        vehicle={vehicle}
                        onError={handleOnVehicleError}
                        onDelete={handleOnVehicleDelete}
                        onUploadDocument={handleOnVehicleUploadDocument}
                        onDeleteDocument={handleOnVehicleDeleteDocument}
                        onUpdate={handleOnVehicleUpdate}
                        appType={MovaAppType.INDIVIDUAL} editMode={vehicleEditMode} />
                </Grid>

            </MovaDialog>
        }
    </>
);
}

export default VehicleDetailsDialog;
