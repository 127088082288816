import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ErrorHandler } from './helpers/ErrorHandler'; // Le HOC de gestion globale des erreurs
import reportWebVitals from './reportWebVitals';
import Logger from './helpers/Logger';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Activation des logs console si nous ne sommes pas en PRODUCTION sinon contrôle de la variable debugMode
if (process.env.NODE_ENV !== 'production' || localStorage.getItem('debugMode') === 'true') {
  Logger.enableLogging();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AppWithErrorHandler = ErrorHandler(App);

root.render(
    <AppWithErrorHandler />
);

// Enregistrez le Service Worker uniquement en production
if (process.env.NODE_ENV === 'production') {
  serviceWorkerRegistration.register();
} else {
  serviceWorkerRegistration.unregister();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(Logger.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
