import { useState, type FC, CSSProperties, useEffect, useRef } from "react";
import {
	CategoryPrestation,
	Garage,
	Logger,
	MovaDialog,
	MovaFormField,
	PartsApplicationType,
	Prestation,
	PrestationState,
	getApplicationShortLabel,
	getApplicationsShortLabels,
	readCookie,
} from "@movalib/movalib-commons";
import {
	Alert,
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	Checkbox,
	Chip,
	Collapse,
	FormControl,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	List,
	ListItem,
	ListItemButton,
	Select,
	TextField,
	Theme,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	darken,
	styled,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import QuestionIcon from "@mui/icons-material/HelpOutlineRounded";
import NextIcon from "@mui/icons-material/ArrowForwardIosRounded";
import {
	applicationChoiceRequired,
	flexCenter,
	flexStart,
	importIcon,
} from "../../helpers/Tools";
import AppointmentVehicleDialog from "./AppointmentVehicleDialog";
import { PrestationRequest } from "../../helpers/Types";
import { COOKIE_INDIVIDUAL_TOKEN } from "@movalib/movalib-commons/dist/src/helpers/CookieUtils";
import AppointmentCustomerDialog from "./AppointmentCustomerDialog";
import React from "react";
import {
	PALETTE_THIRD_COLOR_LIGHT,
	PALETTE_THIRD_COLOR_MAIN,
} from "../../helpers/Constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { set } from "date-fns";
interface AppointmentPrestationsDialogProps {
	open: boolean;
	garage: Garage;
	onClose: () => void;
}
interface CatergoryPrestationAggrs extends CategoryPrestation {
	prestations: Prestation[];
}

type EventForm = {
	prestations?: MovaFormField;
};

export const displayChipPrestation = (
	prestation: Prestation,
	otherChoice: Prestation,
	application?: any,
	setForm?: (v: any) => void,
) => {
	return (
		<Chip
			key={prestation.id}
			label={
				<>
					{prestation.id === otherChoice?.id ? "Autre..." : prestation.name}{" "}
					{application && getApplicationsShortLabels(application)}
					{setForm && (
						<IconButton
							size="small"
							aria-label={prestation.id.toString()}
							onClick={() => {
								setForm((prevForm: any) => ({
									...prevForm,
									prestations: {
										value: prevForm.prestations?.value?.filter(
											(presta: PrestationRequest) =>
												presta.id !== prestation.id,
										),
									},
								}));
							}}
							color="inherit"
						>
							<CloseIcon sx={{ pointerEvents: "none", fontSize: "18px" }} />
						</IconButton>
					)}
				</>
			}
			avatar={
				importIcon(prestation.code) ? (
					<Avatar
						src={importIcon(prestation.code)}
						style={{ width: "30px", height: "30px" }}
						sx={{ width: 24, height: 24 }}
						alt=""
					/>
				) : undefined
			}
			style={{ margin: "2px" }}
		/>
	);
};
const AppointmentPrestationsDialog: FC<AppointmentPrestationsDialogProps> = ({
	open,
	garage,
	onClose,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [checkedPrestations, setCheckedPrestations] = useState<
		PrestationRequest[]
	>([]);
	const [otherReason, setOtherReason] = useState<string>("");
	const [localOpen, setLocalOpen] = useState<boolean>(open);
	const [openAppointmentVehicle, setOpenAppointmentVehicle] =
		useState<boolean>(false);
	const [openAppointmentCustomer, setOpenAppointmentCustomer] =
		useState<boolean>(false);
	const [openApplicationChoice, setOpenApplicationChoice] =
		useState<boolean>(false);
	const [selectedPrestation, setSelectedPrestation] = useState<
		Prestation | undefined
	>(undefined);
	const [selectedApplications, setSelectedApplications] = useState<
		PartsApplicationType[] | undefined
	>(undefined);
	const [otherChoice, setOtherChoice] = useState<Prestation>();
	const [prestationsWithCategory, setPrestationsWithCategory] = useState<
		CatergoryPrestationAggrs[]
	>([]);
	const [prestationsSearchTerm, setPrestationsSearchTerm] = useState(""); // État local pour la recherche des prestations
	const prestationsSearchInputRef = useRef<HTMLInputElement | null>(null); // Référence pour le champ de recherche
	const [form, setForm] = useState<EventForm>({ prestations: { value: [] } });
	const [expandedCategory, setExpandedCategory] = useState({});
	const [expandedPrestation, setExpandedPrestation] = useState<number | null>(
		null,
	); // ID de la prestation étendue

	const toggleExpandPrestation = (prestationId: number) => {
		setExpandedPrestation((prevId) =>
			prevId === prestationId ? null : prestationId,
		);
	};

	// Si l'utilisateur n'est pas authentifié
	const isAuthenticated: boolean = Boolean(readCookie(COOKIE_INDIVIDUAL_TOKEN));

	useEffect(() => {
		return () => {
			setPrestationsSearchTerm("");
		};
	}, []);

	useEffect(() => {
		setLocalOpen(open);
		// On récupère l'identifiant de la prestation "Autre ..."
		if (garage && garage.prestations) {
			setOtherChoice(garage.prestations.filter((p) => p.code === "OTHER")[0]);
		}
	}, [open]);

	useEffect(() => {
		if (garage) {
			const categoryPrestations = [...garage.prestationCategories];
			const prestationGarage = [...garage.prestations];
			const categoryPrestationTable = [] as CatergoryPrestationAggrs[];
			categoryPrestations.forEach((category: CategoryPrestation) => {
				const prestations = prestationGarage?.filter(
					(p) =>
						p.categoryCode === category.code &&
						p.state === PrestationState.VALIDATED &&
						p.active &&
						p.id !== otherChoice?.id,
				) as Prestation[];
				categoryPrestationTable.push({ ...category, prestations: prestations });
			});
			categoryPrestationTable.sort((a, b) => a.position - b.position);
			setPrestationsWithCategory(categoryPrestationTable);
		}
	}, [garage, otherChoice]);

	function filterSearch(prestationName: string): boolean {
		const prestationN = prestationName
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");
		const search = prestationsSearchTerm
			.normalize("NFD")
			.replace(/[\u0300-\u036f]/g, "");
		return prestationN.includes(search);
	}

	const purgeLocalState = () => {
		setForm({ prestations: { value: [] } });
		setSelectedPrestation(undefined);
		setOpenApplicationChoice(false);
		setSelectedApplications(undefined);
	};

	const handleOnClose = () => {
		purgeLocalState();
		onClose();
	};

	const dialogTitleStyle: CSSProperties = {
		color: darken(theme.palette.primary.main, 0.2),
		fontWeight: 700,
	};

	const handleOnClickVehicleChoice = () => {
		if (isAuthenticated) {
			setOpenAppointmentVehicle(true);
		} else {
			setOpenAppointmentCustomer(true);
		}

		setLocalOpen(false);
	};

	const handleOnCloseAppointmentVehicle = () => {
		setOpenAppointmentVehicle(false);
		setLocalOpen(true);
	};

	const handleOnCloseAppointmentCustomer = () => {
		setLocalOpen(true);
		setOpenAppointmentCustomer(false);
	};

	const handleCancelApplicationChoice = () => {
		setOpenApplicationChoice(false);
		setSelectedApplications(undefined);
	};

	const handleMultipleApplicationsChoice = (value: PartsApplicationType[]) => {
		// Toggle the selection of the application
		setSelectedApplications(value);

		// Si la prestation sélectionnée n'est pas en multiple application, on confirme automatiquement le choix
		if (
			selectedPrestation?.operations &&
			!selectedPrestation.multipleApplication
		) {
			confirmApplicationsChoice(value);
		}
	};

	const confirmApplicationsChoice = (
		value: PartsApplicationType[] | undefined,
	) => {
		// On choisit en priorité les applications transmises, sinon on exploite la valeur en useState
		const applications = value ?? selectedApplications;

		if (selectedPrestation && applications !== undefined) {
			Logger.info(applications);

			// On crée un nouvel objet PrestationRequest
			const req: PrestationRequest = {
				id: selectedPrestation.id,
				code: selectedPrestation.code,
				applications: applications,
			};
			setForm((prevForm) => ({
				...prevForm,
				prestations: {
					value: [...(prevForm.prestations?.value || []), req],
				},
			}));
			setSelectedApplications(undefined);
			setOpenApplicationChoice(false);
		}
	};

	/**
	 * Personnalisation du style des Toogle Buttons
	 * Note : il n'est posssible de le personnaliser directement au niveau
	 * de l'objet "components" du theme.
	 */
	const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
		"& .MuiToggleButtonGroup-grouped": {
			padding: theme.spacing(2),
			marginBottom: theme.spacing(2),
			marginRight: theme.spacing(2),
			"&.Mui-disabled": {
				border: 0,
				backgroundColor: theme.palette.grey[100],
			},
			"&.Mui-selected": {
				backgroundColor: PALETTE_THIRD_COLOR_MAIN,
			},
			"&:not(:first-of-type)": {
				borderRadius: theme.shape.borderRadius,
				border: "1px solid rgba(0, 0, 0, 0.12)",
			},
			"&:first-of-type": {
				borderRadius: theme.shape.borderRadius,
			},
		},
	}));

	const renderPrestation = (prestation: Prestation) => {
		return (
			<>
				<ListItem
					key={prestation.id}
					value={prestation.id}
					onClick={() =>
						prestation.operationsVisible &&
						toggleExpandPrestation(prestation.id)
					}
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "start",
						justifyContent: "center",
						width: "100%",
						p: 0,
					}}
				>
					<Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
						{importIcon(prestation.code) && (
							<img
								src={importIcon(prestation.code)}
								style={{
									width: "20px",
									height: "20px",
									paddingLeft: "4px",
									marginRight: "7px",
								}}
								alt=""
							/>
						)}
						<Typography variant="body1">
							{prestation.id === otherChoice?.id ? "Autre..." : prestation.name}
						</Typography>
						{prestation.operationsVisible && (
							<ExpandMoreIcon
								onClick={(e) => {
									e.stopPropagation();
									toggleExpandPrestation(prestation.id);
								}}
								sx={{
									cursor: "pointer",
									transform:
										expandedPrestation === prestation.id
											? "rotate(180deg)"
											: "rotate(0deg)",
									transition: "transform 0.5s",
								}}
							/>
						)}

						<Box sx={{ flexGrow: 1 }} />
						<Checkbox
							sx={{ p: 0 }}
							checked={form.prestations?.value?.some(
								(prestaRequest: PrestationRequest) =>
									prestaRequest.id === prestation.id,
							)}
							onClick={(e) => e.stopPropagation()}
							onChange={(e) => {
								if (e.target.checked) {
									if (applicationChoiceRequired(prestation)) {
										setSelectedPrestation(prestation);
										setOpenApplicationChoice(true);
									} else {
										setForm((prevForm) => ({
											...prevForm,
											prestations: {
												value: [
													...(prevForm.prestations?.value || []),
													{ id: prestation.id, code: prestation.code },
												],
											},
										}));
									}
								} else {
									setForm((prevForm) => ({
										...prevForm,
										prestations: {
											value: prevForm.prestations?.value?.filter(
												(prestaRequest: PrestationRequest) =>
													prestaRequest.id !== prestation.id,
											),
										},
									}));
								}
							}}
						/>
					</Box>
					{
						<Collapse
							key={`${prestation.code}-content`}
							in={
								expandedPrestation === prestation.id &&
								(prestation.operations?.length ?? 0) > 0
							}
							timeout="auto"
							unmountOnExit
						>
							<Box>
								{prestation.operations?.map((operation) => (
									<ListItem
										key={operation.id}
										sx={{
											fontSize: "0.9rem",
											paddingLeft: "8px",
											display: "flex",
											alignItems: "center",
											fontStyle: "italic",
										}}
									>
										- {operation.name}
									</ListItem>
								))}
							</Box>
						</Collapse>
					}
				</ListItem>
			</>
		);
	};

	const handleCategoryClick = (categoryCode: string) => {
		setExpandedCategory((prevCategory) =>
			prevCategory === categoryCode ? "" : categoryCode,
		);
	};

	const getPrestationStyles = (
		prestation: string,
		prestationList: string[] | null,
		theme: Theme,
	) => {
		return {
			fontWeight:
				prestationList && prestationList.indexOf(prestation) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium,
		};
	};

	return (
		<>
			{garage && (
				<MovaDialog
					fullScreen={isMobile}
					open={localOpen}
					onClose={handleOnClose}
					leafImageColor="yellow"
					title={garage.name}
					titleStyle={dialogTitleStyle}
					sx={{ margin: 0 }}
				>
					{/** Motif du rendez-vous */}
					{!openApplicationChoice && (
						<>
							<Card
								variant="outlined"
								sx={{
									backgroundColor: "transparent",
									borderLeft: "none",
									borderRight: "none",
									p: 0,
									mb: 1,
								}}
							>
								<CardContent sx={{ m: 0, p: 0 }}>
									<Typography
										variant="h6"
										component="div"
										color={theme.palette.text.primary}
										style={flexCenter}
										sx={{ mb: 1 }}
									>
										<QuestionIcon sx={{ mr: 1 }} />
										<b>Motif du rendez-vous</b>
									</Typography>
									<Grid container sx={{ alignItems: "center" }}>
										{/** Le "[...garage.prestations]" permet de manipuler une copie du tableau inital car
										 * la méthode sort() modifie le tableau d'origine. (merci ChatGPT)
										 */}
										<Box sx={{ width: "100%" }}>
											{form.prestations?.value &&
												form.prestations?.value
													.map((presta: PrestationRequest) =>
														prestationsWithCategory
															.flatMap((category) => category.prestations)
															.find(
																(prestation) => prestation.id === presta.id,
															),
													)
													.filter(Boolean)
													.map((prestation: Prestation) => {
														const application = form.prestations?.value.find(
															(presta: PrestationRequest) =>
																presta.id === prestation.id,
														)?.applications;
														return displayChipPrestation(
															prestation,
															otherChoice!,
															application,
															setForm,
														);
													})}
											{form?.prestations?.value.some(
												(presta: PrestationRequest) =>
													presta.id === otherChoice?.id,
											) &&
												otherChoice &&
												displayChipPrestation(
													otherChoice,
													otherChoice,
													null,
													setForm,
												)}
										</Box>
										<TextField
											inputRef={prestationsSearchInputRef}
											placeholder="Rechercher une prestation..."
											fullWidth
											variant="outlined"
											size="small"
											value={prestationsSearchTerm}
											sx={{
												"& .MuiOutlinedInput-root": {
													paddingRight: "2px",
												},
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Button
															variant="contained"
															disableElevation
															color="primary"
															aria-label="clear search"
															onClick={() => {
																setPrestationsSearchTerm("");
																setExpandedCategory("");
															}}
															sx={{
																borderRadius: 1,
																color: "white",
															}}
														>
															<CloseIcon />
														</Button>
													</InputAdornment>
												),
											}}
											onChange={(e) =>
												setPrestationsSearchTerm(e.target.value.toLowerCase())
											}
											onKeyDown={(e) => e.stopPropagation()} // Empêche le Select de réagir aux touches saisies
										/>

										<List sx={{ width: "100%" }}>
											{garage.prestations &&
												(() => {
													// Étape 1 : Trouver toutes les prestations correspondant à la recherche
													const allFilteredPrestations =
														prestationsWithCategory.flatMap(
															(categoryPrestation) =>
																categoryPrestation.prestations.filter(
																	(prestation) =>
																		filterSearch(prestation.name.toLowerCase()),
																),
														);

													// Étape 2 : Si aucun résultat global, afficher un seul "Aucun résultat"
													if (
														prestationsSearchTerm &&
														allFilteredPrestations.length === 0
													) {
														return (
															<ListItem
																key="no-results"
																sx={{
																	textAlign: "center",
																	fontStyle: "italic",
																}}
															>
																Aucun résultat
															</ListItem>
														);
													}

													// Étape 3 : Si la recherche est active, afficher uniquement les prestations correspondantes
													if (prestationsSearchTerm) {
														return allFilteredPrestations.map((prestation) => (
															<ListItem
																key={prestation.id}
																value={prestation.id}
																style={getPrestationStyles(
																	prestation.name,
																	form.prestations?.value,
																	theme,
																)}
																sx={{ fontSize: "0.9rem", paddingLeft: "8px" }}
															>
																{renderPrestation(prestation)}
															</ListItem>
														));
													}

													// Étape 4 : Sinon, afficher les catégories et leurs prestations avec une transition
													return prestationsWithCategory.flatMap(
														(categoryPrestation) => {
															const elements = [
																categoryPrestation.prestations.length > 0 && (
																	<ListItemButton
																		key={`${categoryPrestation.code}-header`}
																		onClick={(event) => {
																			event.stopPropagation();
																			handleCategoryClick(
																				categoryPrestation.code,
																			);
																		}}
																		sx={{
																			cursor: "pointer",
																			fontWeight: "bold",
																			px: 1,
																		}}
																	>
																		{categoryPrestation.name.toUpperCase()}
																		<Box sx={{ flexGrow: 1 }} />
																		<ExpandMoreIcon
																			sx={{
																				cursor: "pointer",
																				transform:
																					expandedCategory ===
																					categoryPrestation.code
																						? "rotate(180deg)"
																						: "rotate(0deg)",
																				transition: "transform 0.3s",
																			}}
																		/>
																	</ListItemButton>
																),
															];

															elements.push(
																<Collapse
																	key={`${categoryPrestation.code}-content`}
																	in={
																		expandedCategory === categoryPrestation.code
																	}
																	timeout="auto"
																	unmountOnExit
																>
																	{categoryPrestation.prestations.map(
																		(prestation) => (
																			<ListItem
																				key={prestation.id}
																				value={prestation.id}
																				style={getPrestationStyles(
																					prestation.name,
																					form.prestations?.value,
																					theme,
																				)}
																				sx={{ fontSize: "0.9rem", pl: 1 }} // Ajouter un padding pour une indentation
																			>
																				{renderPrestation(prestation)}
																			</ListItem>
																		),
																	)}
																</Collapse>,
															);

															return elements;
														},
													);
												})()}
											{otherChoice && (
												<ListItem
													key={otherChoice.id}
													value={otherChoice.id}
													style={getPrestationStyles(
														otherChoice?.name,
														form.prestations?.value,
														theme,
													)}
													sx={{ fontSize: "0.9rem" }}
												>
													{renderPrestation(otherChoice)}
												</ListItem>
											)}
										</List>
									</Grid>
								</CardContent>
							</Card>
							{form?.prestations?.value.some(
								(presta: PrestationRequest) => presta.id === otherChoice?.id,
							) && (
								<TextField
									label="Décrivez votre problème, motif de rendez-vous ..."
									required
									rows={4}
									multiline
									fullWidth
									value={otherReason}
									onChange={(e) => setOtherReason(e.target.value)}
								/>
							)}
							{/* // tu ne peux pas valider si le other reason de autre et vide */}
							<Button
								onClick={handleOnClickVehicleChoice}
								color="primary"
								sx={{ width: "90%", my: 2 }}
								variant="contained"
								disabled={
									form?.prestations?.value.length == 0 ||
									(form?.prestations?.value.some(
										(presta: PrestationRequest) =>
											presta.id === otherChoice?.id,
									) &&
										otherReason === "")
								}
							>
								<NextIcon sx={{ mr: 1 }} />
								Suivant
							</Button>
						</>
					)}

					{/** Choix de l'application (uniquement pour certaines prestations) */}

					{openApplicationChoice && selectedPrestation && (
						<Card
							variant="outlined"
							sx={{ backgroundColor: "transparent", mt: 2, py: 2, mb: 2 }}
						>
							<Typography
								variant="h6"
								component="div"
								color={theme.palette.text.primary}
								style={flexCenter}
								sx={{ px: 2 }}
							>
								<b>{selectedPrestation?.name}</b>
							</Typography>
							<Typography sx={{ mt: 1, mb: 4 }} style={flexCenter}>
								<QuestionIcon sx={{ mr: 1, fontSize: "1.2rem" }} />
								Choisissez l'application
							</Typography>

							<StyledToggleButtonGroup
								orientation={isMobile ? "vertical" : "horizontal"}
								size="small"
								value={selectedApplications}
								//exclusive
								onChange={(e, value: PartsApplicationType[]) => {
									handleMultipleApplicationsChoice(value);
								}}
								aria-label="application-choice"
							>
								{selectedPrestation &&
									selectedPrestation.operations &&
									selectedPrestation.operations
										.filter(
											(o, index, array) =>
												o.application &&
												array.findIndex(
													(op) => op.application === o.application,
												) === index,
										)
										.sort((a, b) => {
											const labelA = getApplicationShortLabel(a.application);
											const labelB = getApplicationShortLabel(b.application);
											return labelB.localeCompare(labelA);
										})
										.map((o, index) => (
											<ToggleButton
												size="large"
												key={index + 100}
												sx={{ width: 100 }}
												value={o.application ?? ""}
											>
												{getApplicationShortLabel(o.application)}
											</ToggleButton>
										))}
							</StyledToggleButtonGroup>
							<Grid container>
								<Grid
									item
									xs={selectedPrestation?.multipleApplication ? 6 : 12}
								>
									<Button
										onClick={handleCancelApplicationChoice}
										color="inherit"
										sx={{ width: "80%", mt: 3 }}
										variant="text"
									>
										Annuler
									</Button>
								</Grid>
								{selectedPrestation?.multipleApplication && (
									<Grid item xs={6}>
										<Button
											onClick={() => confirmApplicationsChoice(undefined)}
											color="primary"
											sx={{
												width: "80%",
												mt: 3,
												color: theme.palette.primary.dark,
											}}
											variant="text"
										>
											<b>Confirmer</b>
										</Button>
									</Grid>
								)}
							</Grid>
						</Card>
					)}
				</MovaDialog>
			)}

			<AppointmentVehicleDialog
				open={openAppointmentVehicle}
				garage={garage}
				prestations={form.prestations?.value}
				otherReason={otherReason}
				onClose={handleOnCloseAppointmentVehicle}
			/>

			{!isAuthenticated && (
				<AppointmentCustomerDialog
					open={openAppointmentCustomer}
					garage={garage}
					prestations={form.prestations?.value}
					otherReason={otherReason}
					onClose={handleOnCloseAppointmentCustomer}
				/>
			)}
		</>
	);
};

export default AppointmentPrestationsDialog;
