import {
	flexLeftRow,
	Loader,
	Logger,
	MovaDialog,
} from "@movalib/movalib-commons";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import LogoLarge from "../assets/images/logo/header_logo_border.png";
import { useEffect, useRef, useState, type FC } from "react";
import { type Stripe, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentAuthorizationForm from "../components/stripe/StripePaymentAuthorizationForm";
import UserService from "../services/UserService";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../slices/snackbarSlice";
import { useHistory, useLocation } from "react-router-dom";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { flexCenter, flexStart } from "../helpers/Tools";
import type { PaymentAuthorization } from "../helpers/Types";

interface PaymentAuthorizationDialogProps {
	open: boolean;
	closable?: boolean;
	onClose?: (success: boolean, message: string) => void;
}

const PaymentAuthorizationDialog: FC<PaymentAuthorizationDialogProps> = ({
	open,
	closable = true,
	onClose,
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const location = useLocation();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	// Identifiant de l'autorisation de paiement transmise en params de l'URL
	const paymentAuthorizationId = useRef<string>();
	const [paymentAuthorization, setPaymentAuthorization] =
		useState<PaymentAuthorization | null>(null);
	const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
	const history = useHistory();

	useEffect(() => {
		const params =
			location !== undefined ? new URLSearchParams(location.search) : undefined;
		paymentAuthorizationId.current = params?.get("id") ?? "";

		Logger.info(paymentAuthorizationId);

		// Récupération de la clé API publishable pour chargement du Promise Stripe
		UserService.getPaymentAuthorization(paymentAuthorizationId.current)
			.then((response) => {
				if (response.success && response.data) {
					// Assurez-vous que TypeScript traite response.data comme un PaymentAuthorization
					const paymentAuthorization =
						response.data as unknown as PaymentAuthorization;

					// Si l'autorisation de paiement est valide nous redirigeons l'utilisateur
					if (onClose && paymentAuthorization.status === "SUCCEEDED") {
						onClose(true, "Votre rendez-vous a déjà été confirmé.");
					}

					setPaymentAuthorization(paymentAuthorization);
					setStripePromise(
						loadStripe(paymentAuthorization.stripePublishableKey),
					);
				} else {
					Logger.error(response.error);
					dispatch(
						setSnackbar({
							open: true,
							message: response.error ?? "Une erreur est survenue",
							severity: "error",
						}),
					);
					history.push("/login");
				}
			})
			.catch((error) => {
				Logger.error(error);
				dispatch(
					setSnackbar({
						open: true,
						message: error ?? "Une erreur est survenue",
						severity: "error",
					}),
				);
			});
	}, []);

	const handleOnClose = (success: boolean, message: string) => {
		if (onClose) {
			onClose(success, message);
		}
	};

	return (
		<>
			<MovaDialog
				fullScreen={isMobile}
				open={open}
				onClose={() => handleOnClose(false, "")}
				leafImageColor="green"
				maxWidth="sm"
				closable={false}
			>
				<Grid item xs={12}>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<img
							src={LogoLarge}
							style={{ width: isMobile ? "50%" : "30%" }}
							alt="Logo MOVALIB"
						/>
						<br />
					</Box>
				</Grid>
				<Grid item xs={12} sx={{ minHeight: "400px !important" }}>
					<Typography sx={{ mt: 1, mb: 1 }} color={theme.palette.primary.dark}>
						<b>CONFIRMATION DU RENDEZ-VOUS</b>
					</Typography>
					<Typography
						sx={{ mt: 1, mb: 3 }}
						color={theme.palette.secondary.dark}
						style={flexCenter}
					>
						<CreditCardIcon sx={{ mr: 1 }} />
						Empreinte bancaire requise
					</Typography>
					{stripePromise !== undefined && paymentAuthorization && (
						<Grid container>
							<Grid
								item
								xs={12}
								sx={{
									color: theme.palette.text.secondary,
									textAlign: "left",
									mb: 3,
								}}
							>
								<Typography variant="body2">
									Date : <b>{paymentAuthorization.appointmentStartDate}</b>
								</Typography>
								<Typography variant="body2">
									Lieu : <b>{paymentAuthorization.appointmentGarageName}</b>
								</Typography>
								<Typography variant="body2">
									Client : <b>{paymentAuthorization.customerFullName}</b>
								</Typography>
								{paymentAuthorization.customerEmail && (
									<Typography variant="body2">
										Email : <b>{paymentAuthorization.customerEmail}</b>
									</Typography>
								)}
							</Grid>
							<Grid item xs={12}>
								<Elements stripe={stripePromise}>
									<StripePaymentAuthorizationForm
										onError={handleOnClose}
										onSuccess={handleOnClose}
										paymentAuthorization={paymentAuthorization}
									/>
								</Elements>
							</Grid>
						</Grid>
					)}
					{(stripePromise === undefined || !paymentAuthorization) && (
						<Typography
							color={"textSecondary"}
							style={flexCenter}
							sx={{
								mt: 8,
								py: 4,
								backgroundColor: theme.palette.grey[200],
								borderRadius: "20px",
								opacity: 0.7,
							}}
						>
							Chargement en cours ...
						</Typography>
					)}
				</Grid>
			</MovaDialog>
		</>
	);
};

export default PaymentAuthorizationDialog;
