import React from 'react';
import AppointmentDoneIcon from '@mui/icons-material/EventAvailableRounded';
import AppointmentScheduledIcon from '@mui/icons-material/EventNoteRounded';
import AppointmentNewIcon from '@mui/icons-material/EventRounded';
import AppointmentCancelledIcon from '@mui/icons-material/EventBusyRounded';
import AppointmentPassedIcon from '@mui/icons-material/EventRepeat';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Event, EventState } from '@movalib/movalib-commons';
import { CSSProperties } from 'react';
import { getRotatedIconStyle } from '../helpers/Tools';

interface AppointmentIconProps {
  appointment: Event;
  withStyle?: boolean;
}

const AppointmentIcon: React.FC<AppointmentIconProps> = ({
  appointment,
  withStyle = false
}) => {
  if (!appointment) return null;

  // Gestion des événements passés
  const isPastEvent = appointment.start && new Date(appointment.start) < new Date();
  const appliedStyle = withStyle ? getRotatedIconStyle(appointment) : {};

  if (isPastEvent) {
    return <AppointmentPassedIcon style={appliedStyle} />;
  }

  // Gestion des états
  switch (appointment.state) {
    case EventState.NEW:
      return <AppointmentNewIcon style={appliedStyle} />;
    case EventState.ACCEPTED:
    case EventState.REJECTED:
    case EventState.COMPLETED:
    case EventState.SCHEDULED:
      return <AppointmentScheduledIcon style={appliedStyle} />;
    case EventState.REQUIRES_PAYMENT_AUTHORIZATION:
      return <CreditCardIcon style={appliedStyle} />;
    case EventState.DONE:
      return <AppointmentDoneIcon style={appliedStyle} />;
    case EventState.CANCELLED:
      return <AppointmentCancelledIcon style={appliedStyle} />;
    default:
      return null;
  }
};

export default AppointmentIcon;