import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { request } from "../helpers/ApiHelper";
import { API_BASE_URL } from "../helpers/Constants";
import { APIMethod } from "../helpers/Enums";
import {
	User,
	Vehicle,
	Event,
	APIResponse,
	request as commonsRequest,
	MovaAppType,
} from "@movalib/movalib-commons";
import StripeClientSecret from "../components/stripe/StripeClientSecret";

export default class UserService {

	static resendPaymentAuthorization(
		req: any,
	): Promise<APIResponse<string>> {
		return commonsRequest(
			{
				url: `${API_BASE_URL}/user/resend-payment-authorization`,
				method: APIMethod.POST,
				body: JSON.stringify(req),
				appType: MovaAppType.INDIVIDUAL				
			}
		);
	}

	static updatePaymentAuthorization(
		paymentAuthorizationId: string,
		req: any,
	): Promise<APIResponse<string>> {
		return commonsRequest({
			url: `${API_BASE_URL}/user/payment-authorization/${paymentAuthorizationId}/update`,
			method: APIMethod.PATCH,
			body: JSON.stringify(req),
			appType: MovaAppType.INDIVIDUAL,
		});
	}

	static getStripeSubscriptionSecret(
		paymentAuthorizationId: string,
	): Promise<APIResponse<StripeClientSecret>> {
		return commonsRequest({
			url: `${API_BASE_URL}/user/stripe-secret/${paymentAuthorizationId}`,
			method: APIMethod.GET,
			appType: MovaAppType.INDIVIDUAL,
		});
	}

	static getPaymentAuthorization(
		paymentAuthorizationId: string,
	): Promise<APIResponse<string>> {
		return commonsRequest({
			url: `${API_BASE_URL}/user/payment-authorization/${paymentAuthorizationId}`,
			method: APIMethod.GET,
			appType: MovaAppType.INDIVIDUAL,
		});
	}

	static deleteMe(): Promise<any> {
		return request({
			url: `${API_BASE_URL}/user`,
			method: APIMethod.DELETE,
		});
	}

	static rejectAppointment(
		dispatch: Dispatch<AnyAction>,
		appointmentId: string,
	): Promise<string> {
		return request(
			{
				url: `${API_BASE_URL}/user/documents/${appointmentId}/reject`,
				method: APIMethod.PATCH,
			},
			dispatch,
		);
	}

	static scheduleAppointment(
		dispatch: Dispatch<AnyAction>,
		appointmentId: string,
	): Promise<string> {
		return request(
			{
				url: `${API_BASE_URL}/user/documents/${appointmentId}/schedule`,
				method: APIMethod.PATCH,
			},
			dispatch,
		);
	}

	static rejectUserDocument(
		dispatch: Dispatch<AnyAction>,
		documentId: string,
		reason: string,
	): Promise<string> {
		return request(
			{
				url: `${API_BASE_URL}/user/documents/${documentId}/reject`,
				method: APIMethod.POST,
				body: reason,
			},
			dispatch,
		);
	}

	static approveUserDocument(
		dispatch: Dispatch<AnyAction>,
		documentId: string,
	): Promise<string> {
		return request(
			{
				url: `${API_BASE_URL}/user/documents/${documentId}/approve`,
				method: APIMethod.PATCH,
			},
			dispatch,
		);
	}

	static getUserAppointment(
		dispatch: Dispatch<AnyAction>,
		eventId: string,
	): Promise<Event> {
		return request(
			{
				url: `${API_BASE_URL}/user/appointments/${eventId}`,
				method: APIMethod.GET,
			},
			dispatch,
		);
	}

	static updateUserProfile(
		req: any,
		dispatch?: Dispatch<AnyAction>,
	): Promise<User> {
		return request(
			{
				url: `${API_BASE_URL}/user`,
				method: APIMethod.PATCH,
				body: JSON.stringify(req),
			},
			dispatch,
		);
	}

	static deleteUserVehicle(
		dispatch: Dispatch<AnyAction>,
		vehicleId: number,
	): Promise<string> {
		return request(
			{
				url: `${API_BASE_URL}/user/vehicle/${vehicleId}`,
				method: APIMethod.DELETE,
			},
			dispatch,
		);
	}

	static cancelUserEvent(
		dispatch: Dispatch<AnyAction>,
		eventId: string,
	): Promise<string> {
		return request(
			{
				url: `${API_BASE_URL}/user/event/${eventId}/cancel`,
				method: APIMethod.PATCH,
			},
			dispatch,
		);
	}

	static updatePhoneNumberOrEmail(req: {
		code: string;
		phoneNumber?: string;
		email?: string;
	}): Promise<string> {
		return request({
			url: `${API_BASE_URL}/user/modifyWithValidation`,
			method: APIMethod.PATCH,
			body: JSON.stringify(req),
		});
	}

	static getActiveUserAppointments(
		dispatch: Dispatch<AnyAction>,
	): Promise<Event[]> {
		return request(
			{
				url: `${API_BASE_URL}/user/appointments/active`,
				method: APIMethod.GET,
			},
			dispatch,
		);
	}

	static getPastUserAppointments(
		dispatch: Dispatch<AnyAction>,
	): Promise<Event[]> {
		return request(
			{
				url: `${API_BASE_URL}/user/appointments/past`,
				method: APIMethod.GET,
			},
			dispatch,
		);
	}

	static addUserVehicle(
		dispatch: Dispatch<AnyAction>,
		req: any,
	): Promise<string> {
		return request(
			{
				url: `${API_BASE_URL}/user/vehicles`,
				method: APIMethod.POST,
				body: JSON.stringify(req),
			},
			dispatch,
		);
	}

	static getUserVehicles(dispatch: Dispatch<AnyAction>): Promise<Vehicle[]> {
		return request(
			{
				url: `${API_BASE_URL}/user/vehicles`,
				method: APIMethod.GET,
			},
			dispatch,
		);
	}

	static validateAccount(req: any): Promise<string> {
		return request({
			url: `${API_BASE_URL}/user/validate-account`,
			method: APIMethod.POST,
			body: JSON.stringify(req),
		});
	}

	static signUp(req: any): Promise<string> {
		return request({
			url: `${API_BASE_URL}/users`,
			method: APIMethod.POST,
			body: JSON.stringify(req),
		});
	}

	/**
	 * TODO : côté API n'envoyer qu'un seul User et non un tableau pour éviter d'avoir à tester
	 * @param email
	 * @param password
	 * @returns
	 */
	static async getCurrentUser(
		dispatch?: Dispatch<AnyAction>,
	): Promise<User | undefined> {
		try {
			return await request(
				{
					url: `${API_BASE_URL}/user/me`,
					method: APIMethod.GET,
				},
				dispatch,
			);
		} catch (error) {
			console.error("Error occurred during getting user:", error);
			return Promise.reject(error);
		}
	}
}
