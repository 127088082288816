import {
	GenderSelector,
	MovaDialog,
	MovaFormField,
	User,
	validateEmail,
	validateField,
	validatePhoneNumber,
	capitalizeFirstLetter,
	ConfirmationDialog,
} from "@movalib/movalib-commons";
import {
	Alert,
	Box,
	Button,
	SelectChangeEvent,
	TextField,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useState, type FC, useEffect } from "react";
import UserService from "../services/UserService";
import { useDispatch } from "react-redux";
import Logger from "../helpers/Logger";
import { setSnackbar } from "../slices/snackbarSlice";
import ConfirmIcon from "@mui/icons-material/Check";
import Loader from "../components/Loader";
import { setUserData } from "../slices/userSlice";
import { LoadingButton } from "@mui/lab";
import { deleteCookies } from "../helpers/Tools";
import { useHistory } from "react-router-dom";

interface SettingsDialogProps {
	open: boolean;
	onClose: () => void;
}

const SettingsDialog: FC<SettingsDialogProps> = ({ open, onClose }) => {
	const history = useHistory();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [user, setUser] = useState<User | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	const [localOpen, setLocalOpen] = useState<boolean>(open);
	const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		refreshUser();
		setLocalOpen(open);
	}, [open]);

	const refreshUser = () => {
		setLoading(true);

		// Récupération des véhicules de l'utilisateur courant
		UserService.getCurrentUser()
			.then((user) => {
				if (user) {
					// Actualisation en local et dans le localStorage
					setUser(user);
				}
			})
			.catch((error) => {
				Logger.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleCloseConfirmDelete = () => {
		setOpenConfirmDelete(false);
	};

	const handleConfirmDelete = () => {
		UserService.deleteMe()
			.then(() => {
				dispatch(
					setSnackbar({
						open: true,
						message: "Votre compte a été supprimé avec succès",
						severity: "success",
					}),
				);
				deleteCookies();
				localStorage.clear();
				history.push("/login");
			})
			.catch((error) => {
				dispatch(
					setSnackbar({
						open: true,
						message:
							"Une erreur s'est produite lors de la suppression de votre compte",
						severity: "error",
					}),
				);
			});

		handleOnClose();
	};

	const handleOnClose = () => {
		onClose();
	};

	return (
		<>
			{user && (
				<MovaDialog
					fullScreen={isMobile}
					open={localOpen}
					onClose={handleOnClose}
					leafImageColor="pink"
					title="PARAMÈTRES"
				>
					<Loader loading={loading} />
					<Alert
						severity="info"
						sx={{ mt: 2 }}
						variant="outlined"
						className="styled-alert"
					>
						Pour toute demande relative à votre compte vous pouvez nous
						contacter à : <b>support@movalib.com</b>
					</Alert>

					<LoadingButton
						loading={loading}
						size="large"
						color="error"
						variant="contained"
						sx={{ mt: 3 }}
						onClick={() => setOpenConfirmDelete(!openConfirmDelete)}
					>
						{" "}
						Supprimer mon compte
					</LoadingButton>
				</MovaDialog>
			)}

			<ConfirmationDialog
				open={openConfirmDelete}
				onClose={handleCloseConfirmDelete}
				onConfirm={handleConfirmDelete}
				message="Êtes-vous sûr de vouloir supprimer votre compte ?"
			/>
		</>
	);
};

export default SettingsDialog;
