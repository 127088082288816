import { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
//import AuthenticationService from '../services/AuthenticationService';
import { useDispatch } from 'react-redux';
import { setUserData, setAuthenticatedState } from '../slices/userSlice';
import { COOKIE_USER_ID } from '../helpers/Constants';
import { createCookie } from '../helpers/Tools';
import { MovaLogin, MovaLoginForm, MovaAppType, User, AuthenticationService } from "@movalib/movalib-commons";
import { AlertColor } from "@mui/material";
import Logger from '../helpers/Logger';
import useDarkMode from '../helpers/hooks/useDarkMode';
import { setSnackbar } from '../slices/snackbarSlice';

const Login: FunctionComponent = () => {

    const isDarkMode = useDarkMode();
    const dispatch = useDispatch();
    const history = useHistory();
    const [alertMessage, setAlertMessage] = useState<string>("");    
    // La sévérité est initialisée à "error" par défaut
    const [alertSeverity, setAlertSeverity] = useState<AlertColor>('error');    
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (form: MovaLoginForm) => {


        try {
            setLoading(true);
            Logger.info(form);
            AuthenticationService.login(MovaAppType.INDIVIDUAL, form.phoneNumberEmail.value, form.password.value)
                .then(response => {

                    if(response.success){
                        Logger.info(response.data);
                        // On fait persister les données utilisateur dans le cache du navigateur
                        dispatch(setUserData(response.data));
                        // On redirige l'utilisateur vers la page par défaut (accueil)
                        history.push('/home');
                    } else {
                        setAlertMessage(response.error ?? "Connexion impossible");
                    }


                }).catch(error => {
                    setAlertMessage(error);
                }).finally(() => {
                    setLoading(false);
                })

        }catch (error){
            console.error('Error occurred during submission:', error);
        }
    }

    const handlOnSubmitForgotPassword = async (email: string) => {
        if(email){

            let query = {
                phoneEmail: email,
                appType: "MOVALIB"
            }

            AuthenticationService.forgotPassword(query)
            .then(response => {
                if(response.success){
                    dispatch(setSnackbar({ open: true, message: response.data ?? "Mot de passe réinitialisé ! (consultez vos messages)", severity: 'success' }));
                }else{
                    dispatch(setSnackbar({ open: true, message: response.error ?? "Problème lors de la réinitialisation du mot de passe, contactez Movalib", severity: 'error' }));
                }
            });
        }
    }


    return (
        <MovaLogin 
            movaAppType={MovaAppType.INDIVIDUAL} 
            onSubmit={handleSubmit} 
            onSubmitForgotPassword={handlOnSubmitForgotPassword}
            alertMessage={alertMessage} 
            alertSeverity={alertSeverity} 
            loading={loading} 
            version={process.env.REACT_APP_VERSION} />
    );
};

export default Login;