import {
	CardCvcElement,
	CardElement,
	CardExpiryElement,
	CardNumberElement,
	PaymentElement,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { useEffect, useRef, useState } from "react";
import UserService from "../../services/UserService";
import { Logger } from "@movalib/movalib-commons";
import type StripeClientSecret from "./StripeClientSecret";
import {
	Alert,
	Button,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import type { PaymentAuthorization } from "../../helpers/Types";

interface StripePaymentAuthorizationFormProps {
	paymentAuthorization: PaymentAuthorization;
	onSuccess: (success: boolean, message: string) => void;
	onError: (success: boolean, message: string) => void;
}

const StripePaymentAuthorizationForm: React.FC<
	StripePaymentAuthorizationFormProps
> = ({ paymentAuthorization, onSuccess, onError }) => {
	const theme = useTheme();
	const stripe = useStripe();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const elements = useElements();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
	const [isCardExpiryComplete, setIsCardExpiryComplete] = useState(false);
	const [isCardCvcComplete, setIsCardCvcComplete] = useState(false);

	// Détermine si tous les champs sont complets
	const isCardComplete =
		isCardNumberComplete && isCardExpiryComplete && isCardCvcComplete;

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!stripe || !elements) {
			console.error("Stripe.js n'est pas encore chargé !");
			return;
		}

		setIsLoading(true);

		try {
			// Récupération du client secret
			const response = await UserService.getStripeSubscriptionSecret(
				paymentAuthorization.id,
			);
			if (!response.success || !response.data) {
				Logger.error(response.error);
				return Promise.resolve(false);
			}

			const stripeClientSecret: StripeClientSecret = response.data;
			Logger.info(stripeClientSecret);

			// Récupérer l'élément Card
			/* 	const cardElement = elements.getElement(CardElement);

			if (!cardElement) {
				console.error("CardElement introuvable.");
				setIsLoading(false);
				return;
			} */

			// Assurez-vous que CardNumberElement est utilisé pour le paiement
			const cardNumberElement = elements?.getElement(CardNumberElement);

			if (!cardNumberElement) {
				console.error("CardNumberElement introuvable.");
				setIsLoading(false);
				return;
			}

			// Confirmer le SetupIntent avec Stripe
			const { setupIntent, error } = await stripe.confirmCardSetup(
				stripeClientSecret.clientSecret,
				{
					payment_method: {
						card: cardNumberElement,
						billing_details: {
							name: paymentAuthorization.customerFullName, // Remplacez par le nom du client
							email: paymentAuthorization.customerEmail, // Remplacez par l'email du client
						},
					},
					return_url: `${window.location.origin}/appointments`, // Spécifiez l'URL de redirection après 3D Secure
				},
			);

			if (error) {
				console.error(
					"Erreur lors de la validation du SetupIntent :",
					error.message,
				);
				setIsLoading(false);
				onError(
					false,
					`Erreur lors de la validation de l'empreinte bancaire : ${error.message}`,
				); // Appelez le callback d'erreur
				return;
			}

			//console.log("SetupIntent validé :", setupIntent);

			// On valide l'empreinte bancaire côté API

			const req = {
				status: setupIntent.status,
			};

			UserService.updatePaymentAuthorization(paymentAuthorization.id, req).then(
				(response) => {
					if (response.success) {
						onSuccess(true, "Rendez-vous confirmé !"); // Appelez le callback de succès
					} else {
						onError(
							false,
							`Erreur lors de la validation de l'empreinte bancaire`,
						);
					}
				},
			);
		} catch (error: any) {
			console.error("Une erreur est survenue :", error);
			onError(false, "Erreur lors de la validation de l'empreinte bancaire"); // Appelez le callback d'erreur
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container>
				<Grid item xs={12} sx={{ mt: 2 }}>
					<div style={{ marginBottom: "16px" }}>
						<CardNumberElement
							className="styled-card-element"
							options={{
								style: {
									base: {
										fontSize: "16px",
										color: "#424770",
										"::placeholder": { color: "#aab7c4" },
									},
									invalid: { color: "#9e2146" },
								},
							}}
							onChange={(event) => setIsCardNumberComplete(event.complete)} // Met à jour l'état
						/>
					</div>
					<div style={{ marginBottom: "16px" }}>
						<CardExpiryElement
							className="styled-card-element"
							options={{
								style: {
									base: {
										fontSize: "16px",
										color: "#424770",
										"::placeholder": { color: "#aab7c4" },
									},
									invalid: { color: "#9e2146" },
								},
							}}
							onChange={(event) => setIsCardExpiryComplete(event.complete)} // Met à jour l'état
						/>
					</div>
					<div style={{ marginBottom: "16px" }}>
						<CardCvcElement
							className="styled-card-element"
							options={{
								style: {
									base: {
										fontSize: "16px",
										color: "#424770",
										"::placeholder": { color: "#aab7c4" },
									},
									invalid: { color: "#9e2146" },
								},
							}}
							onChange={(event) => setIsCardCvcComplete(event.complete)} // Met à jour l'état
						/>
					</div>
					{/* 					<CardElement
						onReady={(element) => {
							cardElementRef.current = element; // Associe la référence lorsque le champ est prêt
						}}
						className="styled-card-element"
						options={{
							style: {
								base: {
									iconColor: theme.palette.primary.dark,
									fontFamily: "Outfit",
									fontSize: "16px",
									fontSmoothing: "antialiased",
									color: "#424770",
									"::placeholder": {
										color: "#aab7c4",
									},
								},
								invalid: {
									color: "#9e2146",
								},
							},
						}}
						onChange={(event) => setIsCardComplete(event.complete)} // Met à jour l'état
					/> */}
				</Grid>
				<Grid item xs={12} sx={{ mt: 2 }}>
					<Alert>
						Aucun montant ne sera débité de votre compte, vos coordonnées
						bancaires ne seront pas conservées.
					</Alert>
				</Grid>
				<Grid item xs={12} sx={{ mt: 6 }}>
					<Button
						type="submit"
						variant="contained"
						fullWidth={isMobile}
						sx={{ minWidth: "200px" }}
						disabled={isLoading || !stripe || !isCardComplete} // Désactiver si la saisie n'est pas complète
					>
						{isLoading ? "Validation en cours..." : "Valider"}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};

export default StripePaymentAuthorizationForm;
