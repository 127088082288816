import { Alert, AppBar, Dialog, DialogContent, DialogTitle, IconButton, Slide, Fade, Toolbar, Typography, useMediaQuery, useTheme, Button, darken, Card, CardContent, Grid } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import type { CSSProperties, FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { DayOfWeek, Garage, Loader, MovaDialog, readCookie } from '@movalib/movalib-commons';
import GarageService from '../services/GarageService';
import { setSnackbar } from '../slices/snackbarSlice';
import PinkLeafImage from "../assets/images/leaf_pink_large.png";
import YellowLeafImage from "../assets/images/leaf_yellow_large.png";
import AppointmentIcon from '@mui/icons-material/TodayRounded';
import ScheduleIcon from '@mui/icons-material/ScheduleRounded';
import MessageIcon from '@mui/icons-material/MailOutlineRounded';
import { findScheduleByDayOfWeek, flexCenter, formatPhoneNumber, getFormattedSchedule } from '../helpers/Tools';
import AppointmentPrestationsDialog from './appointment/AppointmentPrestationsDialog';
import Logger from '../helpers/Logger';
import { PALETTE_THIRD_COLOR_LIGHT } from '../helpers/Constants';
import { COOKIE_INDIVIDUAL_TOKEN } from '@movalib/movalib-commons/dist/src/helpers/CookieUtils';

interface GarageDialogProps {
    garageId: string,
    open: boolean;
    onClose: () => void;
}


/**
 * Composant permettant de gérer l'effet de transition à l'ouverture de la boite de dialogue
 */
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Fade ref={ref} timeout={200} {...props} />;
  });

const GarageDialog: FC<GarageDialogProps> = ({ garageId, open, onClose }) => {
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const dispatch = useDispatch();
    const [message, setMessage] = useState<string>("");
    const [garage, setGarage] = useState<Garage>();
    const [localOpen, setLocalOpen] = useState<boolean>(false);  
    const [openAppointmentPrestations, setOpenAppointmentPrestations] = useState<boolean>(false);
    // Si l'utilisateur n'est pas authentifié
    const isAuthenticated:boolean = Boolean(readCookie(COOKIE_INDIVIDUAL_TOKEN));
    
    const dialogTitleStyle: CSSProperties = {
        color: darken(theme.palette.primary.main, 0.2),
        fontWeight: 700
    }

    useEffect(() => {
        
        if(open)
            refreshGarage();
        
        setLocalOpen(open);

    }, [open]);

    const refreshGarage = () => {

        if(garageId){
            // Récupération des données du garage sélectionné
            GarageService.getGarageInfos(dispatch, garageId)
            .then(garage => {

                Logger.info(garage);
                setGarage(garage);

            }).catch(error => {
                Logger.error(error);
                dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                history.push('/home');
            });
        }
    }

    const purgeLocalState = () => {
        setMessage("");
    }
      
    const handleOnClose = () => {
        purgeLocalState();
        onClose();
    }

    const handleOnClickNewAppointment = () => {
        setLocalOpen(false);
        setOpenAppointmentPrestations(true);
    }

    const handleOnCloseAppointmentPrestationsDialog = () => {
        setOpenAppointmentPrestations(false);
        setLocalOpen(true);
    }

    return (
        <>
        {(!garage && !message) && <Loader loading={true}></Loader>}

            {garage && <MovaDialog fullScreen={isMobile} open={localOpen} closable={isAuthenticated}
                onClose={handleOnClose} leafImageColor="yellow"  maxWidth={'md'}
                title={garage.name} titleStyle={dialogTitleStyle}>

                    {/** TODO : ajouter la description */}
                    <Button onClick={handleOnClickNewAppointment} color="primary" sx={{ width: isMobile ? '90%' : '400px', mt: 2 }} variant='contained'>
                        <AppointmentIcon sx={{ mr: 1 }} />Prendre rendez-vous
                    </Button>
                    {/** Adresse et horaires */}
                    <Grid container columnSpacing={2}>
                        <Grid item xs={isMobile ? 12 : 6}>
                            <Card variant='outlined'
                                sx={{ backgroundColor: 'transparent', mt: 4, pb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" color={theme.palette.text.primary} style={flexCenter} sx={{ mb: 1}}>
                                        <ScheduleIcon sx={{ mr: 1 }} /><b>Adresse et horaires</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.primary" sx={{ pb: 2 }}>
                                    {garage.address && (
                                        <>
                                            {garage.address.streetName && <Typography>{garage.address.streetName}</Typography>}
                                            {garage.address.additional && <Typography>{garage.address.additional}</Typography>}
                                            {(garage.address.postalCode && garage.address.cityName) && <Typography>{garage.address.postalCode}&nbsp;&nbsp;{garage.address.cityName}</Typography>}
                                        </>
                                    )}
                                    </Typography>
                                    {Object.keys(DayOfWeek).map((day, index) => ( 
                                        <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'left'}}>
                                            {getFormattedSchedule(findScheduleByDayOfWeek(garage.schedules, index), index)}
                                        </Typography>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                        
                        <Grid item xs={isMobile ? 12 : 6}>
                            {/** Contact */}
                            <Card variant='outlined'
                                sx={{  backgroundColor: 'transparent', mt: 4, pb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" component="div" color={theme.palette.text.primary} style={flexCenter} sx={{ mb: 1}}>
                                        <MessageIcon sx={{ mr: 1 }} /><b>Contact</b>
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        <a href={`tel:${garage.contactPhone}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <b>{formatPhoneNumber(garage.contactPhone)}</b><br/>
                                        </a>                                        
                                        {garage.contactEmail}
                                    </Typography>                                    
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </MovaDialog>
            }

            {message && <Alert severity="error" sx={{ mb: 2 }}>{message}</Alert>}
   
            {garage && <AppointmentPrestationsDialog 
                        open={openAppointmentPrestations} 
                        garage={garage} 
                        onClose={handleOnCloseAppointmentPrestationsDialog} />
            }
        </>
    );
}

export default GarageDialog;
